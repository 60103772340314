import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from "./Media";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: [
      "HTML5, CSS, Sass, BOOTSTRAP",
      "JavaScript, JQUERY",
      "React, Redux, React Native",
      "PHP, MySQL",
    ],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">Welcome to my personal website</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Ramy</span>
        </h1>

        <h2 className="text-4xl font-bold text-white">
          <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>

        <p className="text-base font-bodyFont leading-6 tracking-wide">
          Full Stack Developer with extensive experience using a wide range of
          languages and technologies.
          <br />
          <br />
          Front-end and interactive development using HTML5, CSS, Sass,
          JavaScript, TypeScript, jQuery, and Bootstrap.
          <br />
          I have strong skills in React framework and application state
          management using Redux.
          <br />
          <br />
          Also, back-end development of web applications using Python, PHP and
          MySQL databases.
          <br />
          <br />
          I have implemented web applications that use Python, PHP as the web
          server programming language and interact with databases using MySQL.
          <br />
          <br />I have strong skills in developing Android and IOS mobile
          applications using React Native.
        </p>
      </div>

      <Media />
    </div>
  );
};

export default LeftBanner;
